/*global $, jQuery, alert, console*/

    "use strict";
    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows() );
        }
    };

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


    $(document).ready(function () {
        contactFormsConfig();
        controlTopMenu();
        copyOnClipboard();
        controlImagesPopup();
        // controlModule6Player();
        module19TabContol();
        module15TabContol();
        controlModule21Player();

        $('.sidenav').sidenav();
        $('.tooltipped').tooltip();
        $('.collapsible').collapsible();
        $('.dropdown-link_material').dropdown();
        $('.tabs').tabs();
        $('.lazy').Lazy();
        $('.modal').modal();
        $('.parallax').parallax();


        $('.animated-scroll').on('click', function (e) {
            var linkHref = $(this).attr('href');
            $('html, body').animate({
                scrollTop: $(linkHref).offset().top -60
            }, 500);
        });

        $('.btn_totop').on('click', function (e) {
           e.preventDefault();
            $('html, body').animate({
                scrollTop: 0
            }, 500);
        });
        $(window).resize(function() {
            addInlineSvg();
        });

        addInlineSvg();

        $('body').on('click','.gdpr-cookie-notice-modal-footer-item-save', function () {
            $('.gdpr-cookie-notice-modal').css('display', 'none');
            $('.gdpr-cookie-notice').css('display', 'none');
        });

        $('body').on('click','.gdpr-cookie-notice-modal-footer-item-statement', function () {
            $('#privacy_popup').modal('open');
        });


        $('.carousel-slider').slick({
            variableWidth: true,
            arrows: false,
            centerMode: false,
            infinite: true,
            dots: false,
            autoplay: true,
            cssEase: 'linear',
            autoplaySpeed: 0,
            speed: 2500,
            draggable: false,
            pauseOnFocus: false,
            pauseOnHover: false
        });



        $('.module9__images-wrap').slick({
            arrows: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: true,
            autoplaySpeed: 2000,
            dots: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });

        if ($(window).width() <= 1024) {
            $('.module13__blocks-wrap').slick({
                arrows: false,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                autoplay: true,
                autoplaySpeed: 3000,
                dots: true,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 450,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            })
        }

        if ($(window).width() <= 600) {
            $('.module3__block-wrap').slick({
                arrows: false,
                infinite: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
            })
        }




        $(window).resize(function() {

            if ($(window).width() <= 1024 && !$('.module13__blocks-wrap').hasClass('slick-slider')) {
                $('.module13__blocks-wrap').slick({
                    arrows: false,
                    infinite: true,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    dots: true,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                        {
                            breakpoint: 450,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        }
                    ]
                })
            } else if ($(window).width() > 1024 && $('.module13__blocks-wrap').hasClass('slick-slider')) {
                $('.module13__blocks-wrap').slick('unslick');
            }


            if ($(window).width() <= 600 && !$('.module3__block-wrap').hasClass('slick-slider')) {
                $('.module3__block-wrap').slick({
                    arrows: false,
                    infinite: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true
                })
            } else if ($(window).width() > 600 && $('.module3__block-wrap').hasClass('slick-slider')) {
                $('.module3__block-wrap').slick('unslick');
            }
        });

    });

    function module19TabContol() {
        if ($('.module19').length) {
            function getHashT() {
                var hashT = window.location.hash;
                return hashT.substring(1); // remove #
            };
            var hashT = getHashT();
            if (hashT.length) {
                $('.module19 .module19__tab[data-id=' + hashT + ']').addClass('active');
                $('.module19 .module19__tab-res[data-id=' + hashT + ']').addClass('active');
                $('.module19 .module19__tab-res_mob[data-id=' + hashT + ']').addClass('active');
                setTimeout(function () {
                    var off_top = $('.module19 .module19__tab[data-id=' + hashT + ']').offset().top - 105;
                    $('html, body').animate({
                        scrollTop: off_top
                    }, 500);
                }, 500);


            } else {
                $('.module19:not(.module19_2) .module19__tab:first-child').addClass('active');
                $('.module19:not(.module19_2) .module19__tab-res:first-child').addClass('active');
                $('.module19:not(.module19_2) .module19__tab-res_mob:nth-child(2)').addClass('active');
            }
            ;
            var module19_2 = $('.module19_2');

            $('.module19__tab').on('click', function (e) {
                e.preventDefault();
                var activeId = $(this).attr('data-id');

                window.location.hash = activeId;

                if ((($(window).width() <= 767) && $(this).hasClass('active')) || (module19_2.length && $(this).hasClass('active'))) {
                    $(this).removeClass('active');

                    $('.module19__tab-res').each(function () {
                        if ($(this).attr('data-id') == activeId) {
                            $(this).removeClass('active');
                        }
                    });

                    return;
                }


                $('.module19__tab').each(function () {
                    if (!module19_2.length) {
                        $(this).removeClass('active');
                    }
                    ;
                    if ($(this).attr('data-id') == activeId) {
                        $(this).addClass('active');
                    }
                });

                $('.module19__tab-res').each(function () {
                    if (!module19_2.length) {
                        $(this).removeClass('active');
                    }
                    ;
                    if ($(this).attr('data-id') == activeId) {
                        $(this).addClass('active');
                    }
                });

            })
        };
    }

    function module15TabContol() {
        $('.module15__tab:first-child').removeClass('btn_black').addClass('btn_green');
        $('.module15__img:first-child').addClass('active');

        $('.module15__tab').on('click', function (e) {
            e.preventDefault();
            var activeId = $(this).attr('data-id');



            $('.module15__tab').each(function () {
                $(this).removeClass('btn_green').addClass('btn_black');
                if($(this).attr('data-id') == activeId) {
                    $(this).removeClass('btn_black').addClass('btn_green');
                }
            });

            $('.module15__img').each(function () {
                $(this).removeClass('active');
                if($(this).attr('data-id') == activeId) {
                    $(this).addClass('active');
                }
            });

        })
    }

    // function controlModule6Player() {
    //     $('.module6__iframe-link').on('click', function (e) {
    //         e.preventDefault();
    //         $(this).hide();
    //         $('#module6Iframe').addClass('active');
    //
    //         var iframesrc= $('#module6Iframe').attr('src');
    //         iframesrc = iframesrc + '?autoplay=1&loop=1&rel=0&wmode=transparent';
    //         $('#module6Iframe').attr('src', iframesrc);
    //
    //
    //     })
    // }

    function controlModule21Player() {
        if (window.location.hash == '#playVideo') {
            $('.module21__iframe-link').hide();
            $('#module21Iframe').addClass('active');
            var iframesrc= $('#module21Iframe').attr('src');
            iframesrc = iframesrc + '?autoplay=1&loop=1&rel=0&wmode=transparent';
            $('#module21Iframe').attr('src', iframesrc);
        }


        $('.module21__iframe-link').on('click', function (e) {
            e.preventDefault();
            $(this).hide();
            $('#module21Iframe').addClass('active');
            var iframesrc= $('#module21Iframe').attr('src');
            iframesrc = iframesrc + '?autoplay=1&loop=1&rel=0&wmode=transparent';
            $('#module21Iframe').attr('src', iframesrc);
        });
    }

    function controlImagesPopup() {
        $('#image-popups').magnificPopup({
            delegate: 'a',
            type: 'image',
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
                beforeOpen: function() {
                    // just a hack that adds mfp-anim class to markup
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = this.st.el.attr('data-effect');
                }
            },
            gallery: {
                enabled: true
            },
            closeOnContentClick: true,
            midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
        });
    }

    function copyOnClipboard() {
        $('.copy-block').on('click', function() {
            var copyText = $(this).prev('a').text();
            navigator.clipboard.writeText(copyText);
            var element = $(this);
            element.addClass('active');
            element.addClass('active-copy');
            setTimeout(function(){ element.removeClass('active'); }, 200);
            setTimeout(function(){ element.removeClass('active-copy'); }, 1000);
        })
    }

    function controlTopMenu() {
        var scrollHeight = $(window).scrollTop();

        if(scrollHeight  > 0) {
            $('.top-menu').addClass('not-top');
        } else {
            $('.top-menu').removeClass('not-top');
        }
        $(window).scroll(function() {
            scrollHeight = $(window).scrollTop();

            if(scrollHeight  > 0) {
                $('.top-menu').addClass('not-top');
            } else {
                $('.top-menu').removeClass('not-top');
            }
        });


    }




    function addInlineSvg() {
        var mySVGsToInject = document.querySelectorAll('img.image_svg');
        SVGInjector(mySVGsToInject);
    }


    function contactFormsConfig() {
        try {
            $('input[name=url_page]').val(location.origin + location.pathname);
        }catch (e) {

        }
    }








